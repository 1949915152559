/* You can add global styles to this file, and also import other style files */

@import url('./assets/styles/mainModule.scss');
@import url('./assets/styles/paginator.scss');

@font-face {
    font-family: NOTO;
    src: local("Noto"),
      url("./assets//fonts/NotoSansArabic-VariableFont_wdth\,wght.ttf") format("truetype");
  }

  @font-face {
    font-family: MONTSERRAT;
    src: local("MONTSERRAT"),
      url("./assets/fonts//Montserrat-VariableFont_wght.ttf") format("truetype");
  }

.font-montserrat{
    font-family: 'MONTSERRAT';
}
.font-main{
    font-family: 'MONTSERRAT';
}
.rtl{
    &.font-main{
        font-family: 'NOTO' !important;
    }
    .font-main{
      font-family: 'NOTO' !important;
    }
}
body{
    display: block;
    background: #F5F5F5  !important; 
    min-height: 100vh;
    height: 100vh;
    overflow-x: hidden;
}

:root {
    --sideNavWidth : 20%;

    
    // --main-purple: #5C60F5;
    --main-purple: #007AFF;
    --dark-purple:#483EA8;
    --dark-text:   #535763;
    --ligth-blue:  #F5F9FC;
    --ligth-green: #D0F3E7;
    --light-purple:#6759FF;
    --purple-text: #364F6B;
    --dark-purple:#4840A4;


    // todo:vatlex
    --global-color-accent: #0FD86A;
    // --global-color-accent: #11C387;
    --global-color-secondary: #007AFF;
    // --global-color-secondary: #5C60F5;
    --global-color-primary: #062F60; 
    // --global-color-primary: #4C4C4C; 


    // Heading Color
    --heading: #303030;
    // Text Color
    --text: #707070;
    // Gray Background
    --bg-gray: #e7f2fd;
    // Primary Color
    --primary: #669FDB;
    // White Color
    --white: #ffffff;
    // Dark Color
    --dark: #000000;
    // Border Color
    --border: #ebebeb;
    // Hover Color
    --hover: #669FDB;
    // Secondary Color
    --secondary: #a6a6a6;

    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
}
.main-purple-bg{
  background-color: var(--main-purple) !important;
}
.main-purple-color{
  color: var(--main-purple) !important;
}
.light-purple-bg{
  background-color: var(--light-purple);
}
.dark-purple-bg{
  background-color: var(--dark-purple);
}
.purple_Text{
  color: var(--purple-text);
}
.ligth-blue-Bg{
  background: var(--ligth-blue) !important;
}
.ligth-blue-border{
  border: 1px solid var(--main-purple);
}
.light-purple-text{
  // color: var(--light-purple);
  color: var(--global-color-secondary);
}
.dark-text-color{
    color: var(--dark-text);
}
.blue_Color_BG{
    background-color: var(--main-purple);
   }
.green_Color_BG{
  background-color:#11C387;
  // background-color:#0FD86A;
  
  // todo:vatlex
  background-color: var(--global-color-accent);
   }
.green_Color_text{
    color:#11C387;
    
   }
   .light-green-BG{
    background-color: var(--ligth-green);
   }
   
.lightBlue_Color_BG{
    background-color:#D0DEF3 !important;
    
    
   }
   .darkBlue-text{
    // color: #5C60F5;
    color: var(--global-color-secondary);
   }
   .white-text{
    color: white;;
   }
   .bluebackground{
      background-color: #5377AE !important;
      color: white !important;
   }
   .purplebackground{
      // background-color: #5C60F5 !important;
      background-color: var(--global-color-secondary) !important;
      color: white !important;
   }
   .whitebackground{
      background-color: white !important;
      // color: #5C60F5 !important;
      color: var(--global-color-secondary) !important;
   }
   .red_Color_BG{
     background-color: #F44545;
    }
.rtl{
    *{
     direction: rtl;   
    }
}
.cursor-pointer {
    cursor: pointer;
}

.limit_text{
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.boxShadow-10{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.boxShadow-25{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.lightblueColor{
  // color: #669fdb;
  color: var(--global-color-secondary);
}

.defaultBg{
    background: #FCFCFC;
  }
  .borderRadius_10px{
    border-radius: 10px !important;
  }
  .borderRadius_20px{
    border-radius: 20px !important;
  }
  .borderRadius_30px{
    border-radius: 30px !important;
  }

.HLine{
    background: #D9D9D9;
    color: #D9D9D9;
    height: 2px;
}
.width-20{
  width: 20% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
}
.width-325{
  width:325px;
  @media screen and (max-width: 768px) {
    width: 100% !important;
}
}
.width-50{
  width: 50% !important;
  @media screen and (max-width: 769px) {
      width: 100% !important;
  }
}
.width-73{
  width: 73% !important;
  @media screen and (max-width: 1024px) {
      width: 100% !important;
  }
}
.width-20-lg{
  width: 20% !important;
  @media screen and (max-width: 1024px) {
      width: 100% !important;
  }
}
.width-280px{
  width: 280px;
}
.width-45{
  width: 45% !important;
  @media screen and (max-width: 769px) {
      width: 100% !important;
  }
}

.width-40{
  width: 40% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-48{
  width: 48% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-60{
  width: 60% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-46{
  width: 46% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-46-lg{
  width: 46% !important;
  @media screen and (max-width: 1024px) {
      width: 100% !important;
  }
 }
.width-50-lg{
  width: 50% !important;
  @media screen and (max-width: 1024px) {
      width: 100% !important;
  }
 }
.width-55-lg{
  width: 55% !important;
  @media screen and (max-width: 1024px) {
      width: 100% !important;
  }
 }
.width-19{
  width: 19% !important;
  @media screen and (max-width: 881px) {
      width: 100% !important;
  }
 }
.width-17{
  width: 17% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-16{
  width: 16% !important;
  @media screen and (max-width: 768px) {
      width: 100% !important;
  }
 }
.width-5{
  width: 5% !important;
  @media screen and (max-width: 881px) {
      width: 100% !important;
  }
 }
.width-16{
  width: 16% !important;
  @media screen and (max-width: 881px) {
      width: 100% !important;
  }
 }
.width-49{
  width: 49% !important;
  @media screen and (max-width: 1025px) {
      width: 100% !important;
  }
 }
 .w-45{
  width: 45%;
  @media screen and (max-width: 1025px) {
    width: 100% !important;
  }
 }
.width-12{
  width: 12% !important;
  @media screen and (max-width: 881px) {
      width: 100% !important;
  }
 }
 .width-32{
  width:32%;
  @media screen and (max-width: 768px) {
    width: 100% !important;
}
 }
 .width-30{
  width:30%;
  @media screen and (max-width: 768px) {
    width: 100% !important;
}
 }
 
.h-46px{
height: 46px !important;
 }
.h-60px{
height: 60px !important;
 }
 .h-115px{
  height: 115px !important;
 }
 .h-119px{
  height: 119px !important;
 }
 .h-69px{
  height: 69px !important;
 }
 .h-90px{
  height: 90px !important;
 }
 .underLined-text{
 text-decoration: underline;
 }
 .fs-10{
font-size: 10px;
 }
 .fs-12{
font-size: 12px;
 }
 .fs-14{
font-size: 14px;
 }
 .fs-16{
font-size: 16px;
 }
 .fs-18{
font-size: 16px;
 }
 .fs-20{
font-size: 20px !important;
 }
 .fs-24{
font-size: 24px !important;
 }
 .fs-48{
  font-size: 48px !important;
 }
 .fw-300{
font-weight: 300;
 }
 .fw-400{
font-weight: 400;
 }
 .fw-800{
font-weight: 800;
 }
 .fs-33{
  font-size: 33px !important;
  }
  .fs-18{
  font-size: 18px !important;
  }

  .width-250{
    width: 250px;
}
.fs-21{
    font-size: 21px;
}
.fs-19{
    font-size: 19px;
}
.fs-14{
    font-size: 14px;
}
.h-40{
  height: 40%;
}
 .gap-8{
  gap: 8rem;
  @media screen and (max-width:769px) {
    gap: 1rem !important;
  }
 }
 .gap-6{
  gap: 5rem;
  @media screen and (max-width:769px) {
    gap: 1rem !important;
  }
 }
 .red-color{
  color: red;
 }
  /* --------------------------------------------- */
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
     // todo:vatlex
    // background: #5c61f56c;
    background: #007bff92;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    // background: var(--main-purple);
    background: var(--global-color-secondary);
  }
  .color-lightBlue{
    // todo:vatlex
    // color: #679FDB;
    color: var(--global-color-secondary);
  }
  .color-darkPurple{
    color: var(--dark-purple);
  }

  
  
  .w-20{
    width: 20%;
  }
  .w-80{
    width: 80%;
  }
  .full-width-sm{
    @media screen and (max-width:426px) {
      width:100% !important;
    }
  }
  .no-resize{
    resize: none !important;
  }

  .h-48{
    height: 48px ;
    border: 1px solid #DDDDDD;
    }
    .width-440px{
      width: 440px;
    }
    .width-476px{
      width: 476px;
    }
    .justifay-center-sm{
      @media screen and (max-width:537px){
          justify-content: center !important;
      }
  }

  /* Hide the number input's default increment and decrement buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Right-align the placeholder text */
input[type="number"] {
      text-align: justify;
}

[dir='ltr'] input[type="number"]::-webkit-inner-spin-button,
[dir='ltr'] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[dir='rtl'] input[type="number"]::-webkit-inner-spin-button,
[dir='rtl'] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[dir='ltr'] input[type="number"] {
  text-align: left;
  direction: ltr;
}

[dir='rtl'] input[type="number"] {
      text-align: justify;
  direction: rtl;
}

::ng-deep{
  [dir='rtl'] .apexcharts-yaxis {
       transform: translate(-0.125px, 0px) !important;
   }
}
.purple_BG{
  // background-color: #5C60F5;
  background-color:  var(--global-color-secondary);
}
.purple_text{
  // color: #5C60F5;
  color: var(--global-color-secondary);
}
::-ms-reveal {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;

}
.calenderImg{
  z-index: 1047 !important;
}
::ng-deep{
  .p-calendar .p-inputtext {
    z-index: 10000 !important;
  }
  
  .rtl{
    .p-datepicker .p-datepicker-header .p-datepicker-prev  {
      order: 3 !important;
    }
  .p-datepicker .p-datepicker-title {
      order: 2 !important;
    }
  
    .p-datepicker .p-datepicker-header .p-datepicker-next{
    order: 1 !important;
    }
  }

.p-datepicker .p-datepicker-title {
    order: 2 !important;
  }


  .ltr{
    .p-datepicker .p-datepicker-prev {
      order: 1 !important;
    }
    .p-datepicker .p-datepicker-next {
        order: 2 !important;
      }
  }
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

// new css
.btn_1, .btn_2:hover, .main_menu .navbar-toggler:after, .banner_part .banner_text .btn_1, .feature_part .single_feature:hover span {
  // todo:vatlex
  background: linear-gradient(to left, #28206C 0%, #0FD86A 51%, #0FD86A 100%);
  // background: linear-gradient(to left, #28206C 0%, #669FDB 51%, #669FDB 100%);
}
html[dir=ltr] .banner_part, html[dir=ltr] .blog_page, html[dir=ltr] .page {
  background-image: url('./assets/images/newLanding/banner_en_bg.png');
  background-position: top left;
}

// .rtl{
//   .banner_part:after, .blog_page:after {
//     left: 9% !important;
//     right: auto !important;
// }
// }

.font_Tajawal{
  font-family: "Tajawal", sans-serif;
}

.min_height_100vh{
  min-height: 100vh;
}

.w-90{
  width: 90% !important;
}
.row{
  --bs-gutter-x: 0rem !important;
  @media screen and (max-width: 1024px) {
    width: 92% ;
  }
}

p{
  word-break: break-word;
}

.break_word{
  word-break: break-word;
}
.sumbitBtn{
  width: 120px;
  @media screen and (max-width:769px) {
    width: 100% !important;
  }
}

.h-103{
  height: 103%;
}
.bg-secondary{
  color: white;
  background-color: #959595 !important;
  border-color: #959595 !important;
}
.badge-danger {
  color: #fff;
  background-color: #e81500;
}
.badge-primary {
  color: white;
  background-color: var(--global-color-secondary) !important;
  border-color: var(--global-color-secondary) !important;
}

.min_height_69px{
  min-height: 69px;
}
.err {
  color: red;
  font-size: 12px;
  font-size: 14px;
}

::ng-deep .rtl .p-datepicker .p-datepicker-header .p-datepicker-prev  {
  transform: rotateY(180deg);
}
::ng-deep .rtl .p-datepicker .p-datepicker-header .p-datepicker-next {
  transform: rotateY(180deg);
}

.errorInput {
  border: 2px solid red !important;
  color: red !important;
}

.prodBtn{
  border-radius: 15px;
  width: 100%;
  height: 50px;
  img{
      width: 30px;
  }
  span{
      font-size: 12px;
    }
    @media only screen and (max-width: 769px) {
      width: 100%;
        span{
            font-size: 10px;
        }
 }
}
.width-8{
  width: 8% !important;
  @media screen and (max-width: 881px) {
      width: 100% !important;
  }
}

.width-12{
  width: 12%;
  @media screen and (max-width: 768px) {
      width: 100%;
  }
 }
 .width-18{
  width: 18%;
  @media screen and (max-width: 768px) {
      width: 100%;
  }
 }
 .width-4{
  width: 4%;
  @media screen and (max-width: 768px) {
      width: 100%;
  }
 }
 .width_48px{
  width: 48%;
   @media screen and (max-width: 768px) {
      width: 100%;
  }
 }

 .productTitle{
  font-size: clamp(10px, 0.8vw, 20px);
  font-weight: 800;
 }
.pointer-events-none{
  pointer-events: none
}

.disable_textarea_resize {
  resize: none;
}

.LockItem{
  .lockOverlay{
    display: none;
  }
  &:hover{
    .lockOverlay{
      display: flex;
    }
  }
}
.lockOverlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(64 64 65 / 72%);
  border-radius: 5px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline: 10%;
  display: none;
}

.totalBeforeTaxCard{
  background-color: var(--global-color-accent) !important;
}
.totalTaxCard{
  background-color: var(--global-color-secondary) !important;
}
.totalAfterTaxCard{
  background-color: var(--global-color-primary) !important;
}

.secondaryColor{
  color: var(--global-color-secondary);
}

.rtl{
  .phoneInput{
    direction: ltr;
    text-align: end;
  }
}
  
.Total{
  background-color: var(--global-color-accent);
}
.TotalII{
  background-color: var(--global-color-primary);
}
.TotalIII{
  background-color: #4C4C4C;
}
.betaTag{
  padding: 1% 3%;
}